// import { useSelector } from "react-redux";
import { useSelector } from "react-redux";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const RequiredAuth = () => {
    const isAuthorized = useSelector((state) => state?.user?.isAuthorized);
  const location = useLocation();
  return isAuthorized ? (
    <>
    <Header />
    <Sidebar />
    <Outlet />
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequiredAuth;
