import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { reportWithDate, adminDownload } from "../../apiCalls";
import * as XLSX from "xlsx";
import { toast, Toaster } from "react-hot-toast";
import { reportDownload } from "../../apiCalls";
import ReportItem from "./components/reportItem/reportItem";
import "./datereport.scss";
import PageNav from "../../components/pageNav/PageNav";
import InfoTable from "./components/infoTable/InfoTable";
import { UserToken } from "../../redux/userRedux";

const Datareport = () => {
  const userToken = useSelector(UserToken);
  const initialValues = { start_date: "", end_date: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const auth = useSelector((state) => state.user.user.auth_token);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  
  
  const maxFormateDate = () => {
    const today = new Date(); 
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    
    const formattedToday = `${dd}-${mm}-${yyyy}`;
    //console.log(formattedToday)
    return formattedToday;
  }

  let maxDate = maxFormateDate()
  //console.log("max Date", maxDate)
  
  const formatDateReverse = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    let newDate = `${day}-${month}-${year}`;
    return newDate;
  };
  const formatDate = (dateStr) => {
    const [day, month, year] = dateStr.split("-");
    let newDate = `${year}-${month}-${day}`;
    return newDate;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: formatDateReverse(value),
    });
    //console.log(formValues)
  };
  //console.log(formatDate(formValues.start_date))
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    var generate = true;
    var start_date = formValues.start_date;
    var end_date = formValues.end_date;
    var page = 1;
    var queryParams = { generate, start_date, end_date, page };
    adminDownload(auth, queryParams)
      .then((response) => {
        //console.log("response", response);
        const worksheet = XLSX.utils.json_to_sheet(response);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `${start_date} To ${end_date}.xlsx`);
        setIsLoading(false);
        toast.success("Successfully downloaded");
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something went wrong");
        throw error;
      });
  };

  var res;
  var currentPage = 1;

  const getData = async (page) => {
    //console.log("in function");
    //console.log(userToken);
    var queryParams = { page };
    try {
      res = await adminDownload(`Token ${userToken}`, queryParams);
      setData(res);
      //console.log(res);
      //console.log(data);
      currentPage = page;
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    // getData(1);
  }, []);

  return (
    <>
      <div className="main-container">
        {/* <div className="page-nav">
            <PageNav index={1} currentPage={currentPage} TotalPage={30} getData={getData}/>
          </div> */}
        <div className="container mx-5">
          <div>
            <div className="date-report">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="form-content">
                  <div className="input-container">
                    <label htmlFor="start_date">Start Date</label>
                    <input
                      type="date"
                      placeholder="start date"
                      name="start_date"
                      id="start_date"
                      max= {formatDate(maxDate)}

                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-container">
                    <label htmlFor="end_date">End Date</label>
                    <input
                      type="date"
                      placeholder="end date"
                      name="end_date"
                      id="end_date"
                      min={formatDate(formValues.start_date)}
                      max= {formatDate(maxDate)}
                      disabled={formValues.start_date === ""}
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <button className="downloadbutton" type="submit" disabled={isLoading}>
              {isLoading === true? (
                    <svg
                    className="spinner" 
                      width="20"
                      height="20"
                      viewBox="0 0 13 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.38798 12.616C3.36313 12.2306 2.46328 11.5721 1.78592 10.7118C1.10856 9.85153 0.679515 8.82231 0.545268 7.73564C0.411022 6.64897 0.576691 5.54628 1.02433 4.54704C1.47197 3.54779 2.1845 2.69009 3.08475 2.06684C3.98499 1.4436 5.03862 1.07858 6.13148 1.01133C7.22435 0.944078 8.31478 1.17716 9.28464 1.68533C10.2545 2.19349 11.0668 2.95736 11.6336 3.89419C12.2004 4.83101 12.5 5.90507 12.5 7"
                        stroke="white"
                      />
                    </svg>
                  ) : (
                    "Download"
                  )}
                </button>
              </form>
              {/* <div className="reportResult">
          {data.map((item, i) => (
                ////console.log(item.id)
              <ReportItem record={item} index={i}/>))}
              </div> */}

              {/* <div>
              {data.map((item, i) => (
                ))} */}
            </div>
            {/* <table className="detailTable">
            <thead id="table-head">
              <tr>
                <th>Guest Code</th>
                <th>Client Name</th>
                <th>Doctor Name</th>
                <th>Date</th>
                <th>Service Name</th>
                <th>Service Id</th>
                <th>Flow Completed</th>
              </tr>
            </thead>
            <tbody>
                <InfoTable  index={currentPage} records={data}/>
            </tbody>
          </table> */}
          </div>
        </div>
      </div>
      <Toaster position="bottom-right" reverseOrder={false} />
    </>
  );
};

export default Datareport;
