import "./diagnosisItem.scss";
import { Link } from "react-router-dom";
import { setIsSidebarOpen } from "../../../../redux/sidebarRedux";
import { useDispatch } from "react-redux";
import maskImage from "../../../../images/Mask Group.png";

const DiagnosisItem = ({ index, record }) => {
  const dispatch = useDispatch();
  return (
      <div className="card">
      <div className="diagnosis-heading">
        <h3 className="name">{record.patient.patient_name}</h3>
        <h3>{record.created_date}</h3>
      </div>
        <img
          src={record.d_image}
          onError={event => {
            event.target.src = "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg"
            event.onerror = null
          }}
          alt="..."
        />
        <div className="card-button">
          <Link to={`/diagnosis-profile/${record.patient.id}`}>
            <button>View profile</button>
          </Link>
        </div>
    </div>
  );
};

export default DiagnosisItem;
