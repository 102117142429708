import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./doctorProfile.scss";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserToken } from "../../redux/userRedux";
import { toast, Toaster } from "react-hot-toast";
import maskImage from "../../images/Mask Group.png";
import { updateDoctor } from "../../apiCalls/index";

const DoctorProfile = () => {
  const userToken = useSelector(UserToken);
  const location = useLocation();
  const { info } = location.state || {};
  const [page, setPage] = useState("Doctor");
  const [edit, setEdit] = useState(0);
  const [firstName, setFirstName] = useState(info.fname);
  const [lastName, setLastName] = useState(info.lname);
  const [email, setEmail] = useState(info.email);
  const [clinicName, setClinicName] = useState(info.clinic_name);
  const [clinicEmail, setClinicEmail] = useState(info.clinic_email);
  const [clinicLocation, setClinicLocation] = useState(info.clinic_location);
  const [lastLogin, setLastLogin] = useState(info.last_login);
  const [id, setId] = useState(info.id);
  const [active, setActive] = useState(info.is_active);
  const [drName, setDrName] = useState(`Dr. ${info.fname} ${info.lname}`);
  const [isLoading, setIsLoading] = useState(false);
  const [accountStatus, setAccountStatus ] = useState(info.is_active);
  const [registerDate, setRegisterDate] = useState(info.created_date)
  //console.log(info);

  const handleSelectChange = (event) => { 
    const selectedValue = event.target.value === "1" ? true: false;
    //console.log(event.target.value);
    setActive(selectedValue);
    //console.log(active) // Convert the string to a boolean
  };

  const handleSubmit = async () => {
    try {
    setIsLoading(true);
      //console.log(clinicEmail)
      var query_param = {
        fname: firstName,
        lname: lastName,
        email: email,
        clinic_name: clinicName,
        clinic_email: clinicEmail,
        clinic_location: clinicLocation,
        is_active: active,
        created_date: registerDate
      };
      //console.log("Registration Date",registerDate)
      //console.log(info.id);
      var res =await updateDoctor(userToken, info.id, query_param);
      setActive(res.data.is_active)
      setIsLoading(false);
      setEdit(true)
      toast.success("Successfully Updated");
      //console.log("updated", info.id);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong");
      throw error;
    }
  };


  useEffect(() => {
    setEdit(1);
  }, []);
  return (
    <>
      <div className="main-container">
        <div className="align-start-div">
      <div id="pageTitle">
            <Link style={{ textDecoration: "none" }} to="/doctor">
            <button className="back-btn">
            <i className="fa-solid fa-chevron-left"></i>
            </button>
            </Link>
            <h4>Doctor Profile</h4>
          </div>
      <div className="doctor-profile-box">
        {/* <div className="content col"> */}
          <div className="formNav ">
            <img src={maskImage} alt="profilePic" />
            <button
              className={
                page === "Doctor" ? "selected pageOption" : "pageOption"
              }
              onClick={() => setPage("Doctor")}
            >
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="14.5" cy="15" r="14.5" fill="#1F1F1F" />
                <path
                  d="M21.4384 18.0733C20.3695 16.9309 19.0974 16.0851 17.7093 15.5732C19.196 14.4787 20.1726 12.647 20.1726 10.5759C20.1726 7.23211 17.6276 4.51172 14.4994 4.51172C11.3712 4.51172 8.82619 7.23211 8.82619 10.5759C8.82619 12.647 9.80287 14.4787 11.2895 15.5732C9.9015 16.0851 8.62932 16.9309 7.56049 18.0733C5.70704 20.0545 4.68628 22.6886 4.68628 25.4904H6.21958C6.21958 20.6103 9.93389 16.64 14.4994 16.64C19.0649 16.64 22.7792 20.6103 22.7792 25.4904H24.3125C24.3125 22.6886 23.2918 20.0545 21.4384 18.0733ZM14.4994 15.0011C12.2167 15.0011 10.3595 13.016 10.3595 10.5759C10.3595 8.13579 12.2167 6.15068 14.4994 6.15068C16.7822 6.15068 18.6393 8.13579 18.6393 10.5759C18.6393 13.016 16.7822 15.0011 14.4994 15.0011Z"
                  fill="white"
                />
              </svg>
              Doctor Details
            </button>
            <button
              className={
                page === "Setting" ? "selected pageOption" : "pageOption"
              }
              onClick={() => setPage("Setting")}
            >
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="14.5" cy="15" r="14.5" fill="#1F1F1F" />
                <g clip-path="url(#clip0_825_3462)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.1795 16.2C19.1795 15.99 19.2495 15.78 19.2495 15.5C19.2495 15.22 19.2495 15.01 19.1795 14.8L20.6495 13.61C20.7895 13.47 20.7895 13.33 20.7195 13.19L19.3195 10.74C19.2495 10.67 19.1095 10.6 18.8995 10.67L17.1495 11.37C16.7995 11.09 16.3795 10.88 15.9595 10.67L15.6795 8.85C15.7495 8.64 15.5395 8.5 15.3995 8.5H12.5995C12.4595 8.5 12.2495 8.64 12.2495 8.78L11.9695 10.67C11.5495 10.81 11.1995 11.09 10.7795 11.37L9.0995 10.67C8.8895 10.6 8.7495 10.67 8.6095 10.81L7.2095 13.26C7.1395 13.33 7.2095 13.54 7.3495 13.68L8.8195 14.8C8.8195 15.01 8.7495 15.22 8.7495 15.5C8.7495 15.78 8.7495 15.99 8.8195 16.2L7.3495 17.39C7.2095 17.53 7.2095 17.67 7.2795 17.81L8.6795 20.26C8.7495 20.33 8.8895 20.4 9.0995 20.33L10.8495 19.63C11.1995 19.91 11.6195 20.12 12.0395 20.33L12.3195 22.15C12.3195 22.29 12.4595 22.43 12.6695 22.43H15.4695C15.6095 22.43 15.8195 22.29 15.8195 22.15L16.0995 20.33C16.5195 20.12 16.9395 19.91 17.2895 19.63L19.0395 20.33C19.1795 20.4 19.3895 20.33 19.4595 20.19L20.8595 17.74C20.9295 17.6 20.9295 17.39 20.7895 17.32L19.1795 16.2ZM13.9995 17.95C12.6695 17.95 11.5495 16.83 11.5495 15.5C11.5495 14.17 12.6695 13.05 13.9995 13.05C15.3295 13.05 16.4495 14.17 16.4495 15.5C16.4495 16.83 15.3295 17.95 13.9995 17.95Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_825_3462">
                    <rect
                      width="14"
                      height="14"
                      fill="white"
                      transform="translate(7 8.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Settings
            </button>
          </div>
          <div className="form-box">
            {page === "Doctor" ? (
             <div className="form row row-xl-6 row-lg-6 row-md-6 row-sm-10 row-10 ">
                <div className="formHeading  row-xl-1 row-lg-1 row-md-1 row-sm-1 row-10 m-3">
                <h3>{drName}</h3>
                  <button className="edit btn" onClick={() => setEdit(!edit)}>
                    Edit Info
                  </button>
                </div>
                <div className="row row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-sm-1 ">

                  <div className="form-group">
                    <label for="fname">First Name</label>
                    <input
                      type="text"
                      id="fname"
                      className="form-control search-box p-3"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      disabled={edit}
                    />
                  </div>
                  <div className="form-group">
                    <label for="lname">Last Name</label>
                    <input
                      type="text"
                      id="lname"
                      className="form-control search-box p-3"
                      value={lastName}
                      disabled={edit}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-sm-1 ">

                  <div className="form-group">
                    <label for="doctor-email">Doctor Email</label>
                    <input
                      type="email"
                      id="doctor-email"
                      className="form-control search-box p-3"
                      value={email}
                      disabled={edit}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="cname">Clinic Name</label>
                    <input
                      type="text"
                      id="cname"
                      className="form-control search-box p-3"
                      disabled={edit}
                      value={clinicName}
                      onChange={(e) => setClinicName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-sm-1 ">
                  <div className="form-group">
                    <label for="cemail">Clinic Email</label>
                    <input
                      type="email"
                      id="cemail"
                      className="form-control search-box p-3"
                      disabled={edit}
                      value={clinicEmail}
                      onChange={(e) => setClinicEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="clocation">Clinic Location</label>
                    <input
                      type="text"
                      id="clocation"
                      className="form-control search-box p-3"
                      value={clinicLocation}
                      disabled={edit}
                      onChange={(e) => setClinicLocation(e.target.value)}
                    />
                  </div>
                </div>
                <button
                  className="submit btn m-3 p-2"
                  disabled={edit}
                  onClick={() => handleSubmit()}
                >
                                                                {isLoading === true? (
                    <svg
                    className="spinner" 
                      width="20"
                      height="20"
                      viewBox="0 0 13 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.38798 12.616C3.36313 12.2306 2.46328 11.5721 1.78592 10.7118C1.10856 9.85153 0.679515 8.82231 0.545268 7.73564C0.411022 6.64897 0.576691 5.54628 1.02433 4.54704C1.47197 3.54779 2.1845 2.69009 3.08475 2.06684C3.98499 1.4436 5.03862 1.07858 6.13148 1.01133C7.22435 0.944078 8.31478 1.17716 9.28464 1.68533C10.2545 2.19349 11.0668 2.95736 11.6336 3.89419C12.2004 4.83101 12.5 5.90507 12.5 7"
                        stroke="white"
                      />
                    </svg>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            ) : (
             <div className="form row row-xl-6 row-lg-6 row-md-6 row-sm-10 row-10 ">

                <div className="formHeading row-xl-1 row-lg-1 row-md-1 row-sm-1 row-10 m-3">
                  <h3>{drName}</h3>
                  <button className="edit btn" onClick={() => setEdit(!edit)}>
                    Edit Info
                  </button>
                </div>
                <div className="setting">
                  <div className="setting-menu">
                  <div className="setting-label">
                    <h3> Account is: </h3>
                    <h3 className={accountStatus ? "active" : "deactive"}>
                      {accountStatus ?"Active" : "Deactive"}
                    </h3>
                  </div>
                  
<select id="setect-option" name="account status" onChange={handleSelectChange} disabled={edit}>
  <option value="1" >Activate</option>
  <option value="0" >Deactivate</option>
</select>
</div>

<div className="setting-menu">
<div className="setting-label">
<h3>Registration Date</h3>
</div>
<input type="date" 
disabled={edit}
value={registerDate}
onChange={(e) => setRegisterDate(e.target.value)}
/>
</div>

                <button
                  className="submit btn m-3 p-2"
                  disabled={edit}
                  onClick={() => handleSubmit()}
                  >
                                                                {isLoading === true? (
                                                                  <svg
                                                                  className="spinner" 
                      width="20"
                      height="20"
                      viewBox="0 0 13 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.38798 12.616C3.36313 12.2306 2.46328 11.5721 1.78592 10.7118C1.10856 9.85153 0.679515 8.82231 0.545268 7.73564C0.411022 6.64897 0.576691 5.54628 1.02433 4.54704C1.47197 3.54779 2.1845 2.69009 3.08475 2.06684C3.98499 1.4436 5.03862 1.07858 6.13148 1.01133C7.22435 0.944078 8.31478 1.17716 9.28464 1.68533C10.2545 2.19349 11.0668 2.95736 11.6336 3.89419C12.2004 4.83101 12.5 5.90507 12.5 7"
                        stroke="white"
                      />
                    </svg>
                  ) : (
                    "Save"
                  )}
                </button>
</div>

</div>            )}
          </div>
        </div>
      </div>
      </div>
      {/* </div> */}
      <Toaster position="bottom-right" reverseOrder={false} />
    </>
  );
};

export default DoctorProfile;
