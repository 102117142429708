import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import { updatePassword } from "../../apiCalls";
import "./changePassword.css";

const ChangePassword = () => {
  const initialValues = { current_password: "", confirm_password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const auth = useSelector((state) => state.user.user.auth_token);
  const id = useSelector((state) => state.user.user.id);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    //console.log(auth)
    updatePassword(auth, id, { ...formValues })
      .then((response) => {
        toast.success('Successfully Password Updated')
        // setFormValues(initialValues);
        
        setIsLoading(false);

      })
      .catch((error) => {
        toast.error('Something went wrong')
        setIsLoading(false);
        setFormErrors(error.response.data);
        throw error;
      });
  };

  return (
    <>
    <div className="main-container">
    <div className="change-password">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="input-container">
          <label htmlFor="current_password">New password</label>
          <input
            type="password"
            placeholder="enter new password"
            name="current_password"
            id="current_password"
            className={formErrors?.message ? "error" : undefined}
            onChange={(e) => handleChange(e)}
            required
          />
        </div>
        <div className="input-container">
          <label htmlFor="confirm_password">Confirm password</label>
          <input
            type="password"
            placeholder="re-enter new password"
            name="confirm_password"
            id="confirm_password"
            className={formErrors.message ? "error" : undefined}
            onChange={(e) => handleChange(e)}
            required
          />
        </div>
        {formErrors.message && <span>{formErrors.message}</span>}

        <button type="submit">Confirm</button>
      </form>
      <Toaster position="bottom-right" reverseOrder={false} />
    </div>
    </div>
    </>

  );
};

export default ChangePassword;