import "./improvementItem.scss";
import { Link } from "react-router-dom";


const ImprovementItem = ({index , record}) => {
    return (
<div className="measure-card">
<div className="heading-title">
  <h3 className="name">{record.patient.patient_name}</h3>
  <h3>{record.created_date}</h3>
</div>
<div className="photo-container">
  <img
    src={record.image}
    alt="Photo 1"
    onError={event => {
      event.target.src = "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg"
      event.onerror = null
    }}
    className="photo"
  />
  <img
    src={record.process_image}
    alt="Photo 2"
    className="photo"
    onError={event => {
      event.target.src = "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg"
      event.onerror = null
    }}
  />
</div>
<Link to={`/measure-improvement-profile/${record.patient.id}`}   >
<div className="measure-button ">
  <button className="btn btn-view-profile">View</button>
</div>
</Link>
</div>
    )
}

export default ImprovementItem;