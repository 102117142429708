// import React from 'react'

import React, { useState, useRef, useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { doctor} from "../../apiCalls";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import "./doctor.css";
import userprofile from "../../images/Vector.png";
import InfoTable from "./components/infoTable/InfoTable";
import PageNav from "../../components/pageNav/PageNav";
import { UserToken } from "../../redux/userRedux";
import Loader from "../../components/loader/Loader";
 
const Doctor = () => {
  const [doctors, setDoctors] = useState([]);
  const userToken = useSelector(UserToken);
  var res;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [filter, setFilter] = useState("Doctor Email");
  const filterKeyRef = React.useRef("email")
  const filterValueRef = React.useRef("")
  const [isLoading, setIsLoading] = useState(false);

  const [showFilter, setShowFilter] = useState(false);
  const dropdownRef = useRef(null);
  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowFilter(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleChange = (e) => {
    filterValueRef.current = e.target.value;
      getData(1);
  };

  const getData = async (page) => {
    //console.log(page);
    try {
      setIsLoading(true)
      var query_param={page}
      query_param[filterKeyRef.current] = filterValueRef.current; 
      Object.keys(query_param).forEach(
          (k) => query_param[k] === "" && delete query_param[k]
        );
        //console.log("query_param",query_param);
        //console.log("key",filterKeyRef)
        //console.log("value",filterValueRef)
      res = await doctor(userToken, query_param);
      setTotalPage(Math.ceil(res.count / 15));
      //console.log(res);
      setDoctors(res.results);
      setCurrentPage(page);
      setIsLoading(false)
      window.scrollTo(0, 0);
    } catch (error) {
      setIsLoading(false)
      //console.log(error);
    }
    //console.log("function out");
  };
  useEffect(() => {
    getData(1);
  }, []);

  return (
    <>
      <div className="main-container">
        <div className="container mx-5">
          <div className="row search">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-12">
              <div className="form-group">
                <label className="search-label" for="search">Search</label>
                <input
                  type="text"
                  id="search"
                  className="form-control search-box p-3"
                  placeholder={`search by ${filter}`}
                  value={filterValueRef.current}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="search-label" for="filter"> Add Filters</label>
                <div className="filter" ref={dropdownRef}>
                  <div className="menu p-3" onClick={() => setShowFilter(!showFilter)}>
                    <span>Filter by {filter}</span><i className="fa-solid fa-chevron-down"></i>
                  </div>

            { showFilter &&
                  <div className="filter-content">
                  <div className="filterForm ">
                    <button
                      placeholder="doctor_email"
                      name="doctor_email"
                      id="doctor_email"
                        className={filter === "Doctor Email" ? "btn-active" : "btn-unactive"}
                        onClick={() => {setFilter("Doctor Email"); setShowFilter(false); filterKeyRef.current="email";  filterValueRef.current=""}}
                    >
                      Doctor Email
                    </button>
                    <button
                      placeholder="id"
                      name="id"
                      id="id"
                        className={filter === "Doctor ID" ? "btn-active" : "btn-unactive"}
                        onClick={() => {setFilter("Doctor ID"); setShowFilter(false); filterKeyRef.current="id";  filterValueRef.current=""}}
                    >
                     Doctor ID
                    </button>
                    <button
                      placeholder="clinic_name"
                      name="clinic_name"
                      id="clinic_name"
                      className={filter === "Clinic Name" ? "btn-active" : "btn-unactive"}
                      onClick={() => {
                        setFilter("Clinic Name"); setShowFilter(false); filterKeyRef.current="clinic_name";  filterValueRef.current=""
                      }}
                    >
                      Clinic Name
                    </button>
                  <button
                    placeholder="clinic_email"
                    name="clinic_email"
                    id="clinic_email"
                    className={filter === "Clinic Email" ? "btn-active" : "btn-unactive"}
                    onClick={() => {
                      setFilter("Clinic Email"); setShowFilter(false); filterKeyRef.current="clinic_email";  filterValueRef.current=""
                    }}
                  >
                    Clinic Email
                  </button>
                  </div>
                </div>}
                </div>
              </div>
              </div>
          </div>
          {isLoading === true? <Loader/> :
          <div>
            <table>
              <thead id="table-head">
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Clinic Name</th>
                  <th>Doctor Email</th>
                  {/* <th>Last Login</th> */}
                  <th>ID</th>
                </tr>
              </thead>
              <tbody>
                <InfoTable className="info" index={currentPage} records={doctors} />
              </tbody>
            </table>
          </div>}
        </div>
        <div className="page-nav">
          <PageNav
            index={1}
            currentPage={currentPage}
            TotalPage={totalPage}
            getData={getData}
          />
        </div>
      </div>
    </>
  );
};

export default Doctor;
