import React, { useState, useRef, useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { antiAgeImage } from "../../apiCalls";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import "./antiAging.css";
import "../../css/_common.scss";
import { Link } from "react-router-dom";
import AntiAgingItem from "./components/anti-aging-item/AntiAgingItem";
import PageNav from "../../components/pageNav/PageNav";
import { UserToken } from "../../redux/userRedux";
import Loader from "../../components/loader/Loader";


const AntiAging = () => {
  const userToken = useSelector(UserToken);
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [filter, setFilter] = useState("Customer Name, Clinic Name, Mobile No.");
  const filterKeyRef = React.useRef("global")
  const filterValueRef = React.useRef("")
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const dropdownRef = useRef(null);
  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowFilter(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleChange = (e) => {
    filterValueRef.current = e.target.value;
    getData(1);
  };
  var res;

  const getData = async (page) => {
    //console.log("in function");
    try {
      setIsLoading(true);
      var query_param={page}
      query_param[filterKeyRef.current] = filterValueRef.current; 
      Object.keys(query_param).forEach(
          (k) => query_param[k] == "" && delete query_param[k]
        );
        //console.log("query_param",query_param);
        //console.log("key",filterKeyRef)
        //console.log("value",filterValueRef)
      res = await antiAgeImage(userToken, query_param);
      //console.log(currentPage);
      setTotalPage(Math.ceil(res.count / 15));
      //console.log(res.count)
      //console.log(res);
      setImages(res.results);
      setCurrentPage(page);
      setIsLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      //console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData(1);
  }, []);

  return (
    <>
      <div className="main-container">
        <div className="container">
          <div className="row search">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-12 ">
              <div className="form-group ">
                <label className="search-label" for="search">Search </label>
                <input
                  type="text"
                  id="search"
                  vslue={filterValueRef.current}
                  className="form-control search-box p-3"
                  placeholder={`search by ${filter}`}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="search-label" for="filter"> Add Filters</label>
                <div className="filter" ref={dropdownRef} >
                  <div className="menu p-3" onClick={() => setShowFilter(!showFilter)}><span>Filter by {filter}</span><i className="fa-solid fa-chevron-down"></i></div>
{ showFilter &&
                  <div className="filter-content">
                    <div className="filterForm ">
                      <button
                        placeholder="customer_name"
                        name="customer_name"
                        id="customer_name"
                        className={filter === "Customer Name" ? "btn-active" : "btn-unactive"}
                        onClick={() => {setFilter("Customer Name"); setShowFilter(false); filterKeyRef.current="customer_name"; filterValueRef.current=""  }}
                      >
                        Customer Name
                      </button>
                      <button
                        placeholder="customer_code"
                        name="customer_code"
                        id="customer_code"
                        className={filter === "Customer Code" ? "btn-active" : "btn-unactive"}
                        onClick={() => {setFilter("Customer Code"); setShowFilter(false); filterKeyRef.current="customer_code"; filterValueRef.current=""; }}
                      >
                        Customer Code
                      </button>
                      <button
                        placeholder="mobile_no"
                        name="mobile_no"
                        id="mobile_no"
                        className={filter === "Mobile No." ? "btn-active" : "btn-unactive"}
                        onClick={() => {setFilter("Mobile No."); setShowFilter(false);  filterKeyRef.current="mobile_no"; filterValueRef.current=""  }}
                      >
                        Mobile No.
                      </button>
                      <button
                        placeholder="clinic_name"
                        name="clinic_name"
                        id="clinic_name"
                        className={filter === "Clinic Name" ? "btn-active" : "btn-unactive"}
                        onClick={() => {setFilter("Clinic Name"); setShowFilter(false);  filterKeyRef.current="clinic_name"; filterValueRef.current=""  }}
                      >
                        Clinic Name
                      </button>
                      <button
                        placeholder="clinic_email"
                        name="clinic_email"
                        id="clinic_email"
                        className={" none-btn"}
                        onClick={() => {setFilter("Customer Name, Clinic Name, Mobile No."); setShowFilter(false);  filterKeyRef.current="global"; filterValueRef.current=""  }}
                      >
                        None
                      </button>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
          {isLoading === true? <Loader/> :
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
            {images.map((item, i) =>
              ////console.log(item.id)
              item === null ? <></> : <AntiAgingItem record={item} index={i} />
            )}
          </div>}
        </div>
        <div className="page-nav">
          <PageNav
            index={1}
            currentPage={currentPage}
            TotalPage={totalPage}
            getData={getData}
          />
        </div>
      </div>
    </>
  );
};

export default AntiAging;
