import "./menu.scss";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuthorized, setUser } from "../../../redux/userRedux.js";
import { Link } from "react-router-dom";

const Menu = () => {
  const [showDropDown, setShowDropDrown] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
        <div className="dropdown">
    <div className="menu">
      {/* <div className="notification">
        <img
          src={process.env.PUBLIC_URL + "/images/notification-icon.png"}
          alt="notification"
        />
      </div> */}
      <div className="avatar dropbtn" onClick={() => {setShowDropDrown(!showDropDown)}}>
        <img src={process.env.PUBLIC_URL + "/images/avatar.jpg"} alt="avatar" />
      </div>
        </div>

    <div className="dropdown-content">
      <Link to="/change-password" >

<button className="chngBtn" 
        > Change Password </button>
        </Link>
      <a  onClick={ () => {
          dispatch(setUser([]));
          dispatch(setIsAuthorized(false));
        }} className="logout">Logout</a>
    </div>
  </div>

    </>
  );
};

export default Menu;
