import React, { useState, useRef } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import "./transferpatientdata.scss";
import { doctor, doctorTranfer } from "../../apiCalls";

const Trasferpatientdata = () => {
  const initialValues = {
    own_by_doctor_email: "",
    transffer_by_doctor_email: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const fromEmail = React.useRef("");  // used fromEmail and fromDoctorRef for own_by_doctor_email email suggegestion 
  const fromDoctorRef = useRef([]);
  const transferDoctorRef = useRef([]); // used toEmail and transferDoctorRef for transffer_by_doctor_email email suggegestion
  const toEmail = React.useRef("");
  const auth = useSelector((state) => state.user.user.auth_token);

  const handleChange = (e) => {
    const { name, value } = e.target;
    name === "own_by_doctor_email"
      ? (fromEmail.current = value)
      : (toEmail.current = value);
    searchDoctor(name, value);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({});
  };

  const transferPatient = (e) => {
    //console.log(auth);
    doctorTranfer(auth, { ...formValues })
      .then((response) => {
        toast.success("Successfully transferred");
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setIsLoading(false);
        setFormErrors(error.response.data);
        throw error;
      });
  };

  const searchDoctor = async (name, email) => {
    if (email.length > 0) {
      try {
        var page = 1;
        var query_param = { page, email };
        var res = await doctor(auth, query_param);
        name === "own_by_doctor_email"
          ? (fromDoctorRef.current = res.results)
          : (transferDoctorRef.current = res.results);
        //console.log(res.results);
      } catch (error) {
        //console.log(error);
      }
    } else {
      fromDoctorRef.current = [];
      transferDoctorRef.current = [];
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setShowAlert(true);
  };
  return (
    <>
      <div className="main-container">
        {showAlert && (
          <div className="alert-box">
            <dialog open>
              <div>
                <div id="head">
                  <h3>Confirm Patient Transfer</h3>
                  <button id="cross-btn"                         onClick={() => {
                          setShowAlert(false);
                          setIsLoading(false);
                        }}>X</button>
                </div>
                <div id="content">
                  {/* <div className="row row-xl-2 row-lg-2 row-md-2 row-sm-1 row-1 "> */}
                  <div className="row">
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 p-1">
                      <p>From</p>
                    </div>
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 p-1">
                      <b>{fromEmail.current}</b>{" "}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 p-1">
                      <p>To</p>
                    </div>
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 p-1">
                      <b>{toEmail.current}</b>{" "}
                    </div>
                  </div>
                  <div>
                    <span id="warning">
                      Warning: This change cannot be rolled back.
                    </span>{" "}
                    <div id="dialog-btns">
                      <button
                        id="close-btn"
                        onClick={() => {
                          setShowAlert(false);
                          setIsLoading(false);
                        }}
                      >
                        Close
                      </button>{" "}
                      <button
                        id="continue-btn"
                        onClick={() => {
                          setShowAlert(false);
                          transferPatient();
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </dialog>
          </div>
        )}
        <div className="container mx-5">
          <div>
            <form className="transfer" onSubmit={(e) => handleSubmit(e)}>
              <div className="form-content">
                <div className="input-container">
                  <label htmlFor="own_by_doctor_email">From</label>
                  <input
                    type="text"
                    placeholder="own by doctor email"
                    name="own_by_doctor_email"
                    id="own_by_doctor_email"
                    value={fromEmail.current}
                    className={
                      formErrors.message || formErrors.own_by_doctor_email
                        ? "error"
                        : undefined
                    }
                    required
                    onChange={handleChange}
                  />
                  {formErrors.message && <span>{formErrors.message}</span>}
                  {formErrors.own_by_doctor_email && (
                    <span>{formErrors.own_by_doctor_email[0]}</span>
                  )}
                  {fromDoctorRef.current.length !== 0 && (
                    <div className="search-container">
                      {fromDoctorRef.current.map((item, i) => (
                        <div
                          className="search-card"
                          onClick={() => {
                            setFormValues({
                              ...formValues,
                              own_by_doctor_email: item.email,
                            });
                            fromEmail.current = item.email;
                            //console.log(formValues);
                            fromDoctorRef.current = [];
                          }}
                        >
                          <h2>{item.email}</h2>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="transffer_by_doctor_email">To</label>
                  <input
                    type="text"
                    placeholder="transffer by doctor email"
                    name="transffer_by_doctor_email"
                    id="transffer_by_doctor_email"
                    value={toEmail.current}
                    className={
                      formErrors.message || formErrors.transffer_by_doctor_email
                        ? "error"
                        : undefined
                    }
                    required
                    onChange={handleChange}
                  />
                  {transferDoctorRef.current.length !== 0 && (
                    <div className="search-container">
                      {transferDoctorRef.current.map((item, i) => (
                        <div
                          className="search-card"
                          onClick={() => {
                            setFormValues({
                              ...formValues,
                              transffer_by_doctor_email: item.email,
                            });
                            toEmail.current = item.email;
                            //console.log(formValues);
                            transferDoctorRef.current = [];
                          }}
                        >
                          <h2>{item.email}</h2>
                        </div>
                      ))}
                    </div>
                  )}
                  {formErrors.message && <span>{formErrors.message}</span>}
                  {formErrors.transffer_by_doctor_email && (
                    <span>{formErrors.transffer_by_doctor_email[0]}</span>
                  )}
                </div>
              </div>
              <button
                className="transferbutton"
                type="submit"
                disabled={isLoading}
              >
                {isLoading === true ? (
                  <svg
                    className="spinner"
                    width="20"
                    height="20"
                    viewBox="0 0 13 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.38798 12.616C3.36313 12.2306 2.46328 11.5721 1.78592 10.7118C1.10856 9.85153 0.679515 8.82231 0.545268 7.73564C0.411022 6.64897 0.576691 5.54628 1.02433 4.54704C1.47197 3.54779 2.1845 2.69009 3.08475 2.06684C3.98499 1.4436 5.03862 1.07858 6.13148 1.01133C7.22435 0.944078 8.31478 1.17716 9.28464 1.68533C10.2545 2.19349 11.0668 2.95736 11.6336 3.89419C12.2004 4.83101 12.5 5.90507 12.5 7"
                      stroke="white"
                    />
                  </svg>
                ) : (
                  "Transfer"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>

      <Toaster position="bottom-right" reverseOrder={false} />
    </>
  );
};

export default Trasferpatientdata;
