// import React from 'react'
import CircularProgress from "@mui/joy/CircularProgress";
import React, { useState, useEffect } from "react";
import "../before-profile/beforeProfile.scss";
import "./measureProfile.scss";
import { measureImprovementImagesId } from "../../apiCalls";
import { useParams, Link } from "react-router-dom";
import PageNav from "../../components/pageNav/PageNav";
import maskImage from "../../images/Mask Group.png";
import maskphoto from "../../images/Mask Group.png";
import { UserToken } from "../../redux/userRedux";
import { useSelector } from "react-redux";
import SwiperImage from "../../components/image-slider/SwiperImage";
import Loader from "../../components/loader/Loader";

const MeasureProfile = () => {
  const [profile, setProfile] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showGraph, setShowGraph] = useState(false);
  const initialValues = {
    name: "-",
    age: "-",
    uploadDate: "-",
    processDate: "-",
    beforeImage: "",
    afterImage: "",
    score: "",
  };
  const graphInitial = {
    acne: 0,
    dark_circles: 0,
    dynamic_lines: 0,
    fine_lines: 0,
    hollowness: 0,
    open_pores: 0,
    pigmentation: 0,
    sagging: 0,
    Scar: 0,
    skin_score: 0,
    static_lines: 0,
    under_eye: 0,
  };
  const [graph, setGraph] = useState(graphInitial);
  const [profileDetail, setProfileDetail] = useState(initialValues);
  var res;
  const { id } = useParams();
  const userToken = useSelector(UserToken);
  const [isLoading, setIsLoading] = useState(false);
  const [displayImage, setImageDisplay] = useState(false);


  const updateDetails = (index) => {
    setCurrentIndex(index);
    setProfileDetail({
      ...profileDetail,
      name: profile[index].patient.patient_name,
      age: profile[index].patient.age,
      beforeImage: profile[index].image,
      afterImage: profile[index].process_image,
      processDate: profile[index].updated_date,
      uploadDate: profile[index].created_date,
    });
    setGraph({
      ...graph,
      acne: profile[index].score["Acne"],
      dark_circles: profile[index].score["Dark circles"],
      dynamic_lines: profile[index].score["Dynamic Lines"],
      fine_lines: profile[index].score["Fine lines and wrinkles"],
      hollowness: profile[index].score["Hollowness (Volume loss"],
      open_pores: profile[index].score["Open pores"],
      pigmentation: profile[index].score["Pigmentation"],
      sagging: profile[index].score["Sagging"],
      Scar: profile[index].score["Scar"],
      skin_score: profile[index].score["Skin score"],
      static_lines: profile[index].score["Static lines"],
      under_eye: profile[index].score["Under eye puffiness"],
    });
  };

  const getData = async (page) => {
    //console.log(id);
    try {
      setIsLoading(true);
      res = await measureImprovementImagesId(userToken, id);
      setProfile(res.results);
      console.log(res.results[10]);
      setProfileDetail({
        ...profileDetail,
        name: res.results[0].patient.patient_name,
        age: res.results[0].patient.age,
        beforeImage: res.results[0].image,
        afterImage: res.results[0].process_image,
        processDate: res.results[0].updated_date,
        uploadDate: res.results[0].created_date,
      });
      setGraph({
        ...graph,
        acne: res.results[0].score["Acne"],
        dark_circles: res.results[0].score["Dark circles"],
        dynamic_lines: res.results[0].score["Dynamic Lines"],
        fine_lines: res.results[0].score["Fine lines and wrinkles"],
        hollowness: res.results[0].score["Hollowness (Volume loss"],
        open_pores: res.results[0].score["Open pores"],
        pigmentation: res.results[0].score["Pigmentation"],
        sagging: res.results[0].score["Sagging"],
        Scar: res.results[0].score["Scar"],
        skin_score: res.results[0].score["Skin score"],
        static_lines: res.results[0].score["Static lines"],
        under_eye: res.results[0].score["Under eye puffiness"],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    //console.log("close Clicked")
    setImageDisplay(false);
  };

  useEffect(() => {
    getData(1);
  }, []);

  return (
    <>
      <div className="main-container ">
        {isLoading === true ? (
          <Loader />
        ) : (
          <div>
            {displayImage && (
              <SwiperImage
                images={[profileDetail.beforeImage, profileDetail.afterImage]}
                handleClose={handleClose}
              />
            )}
            <div id="pageTitle">
              <Link
                style={{ textDecoration: "none" }}
                to="/measure-improvement?page="
              >
                <button className="back-btn">
                  <i className="fa-solid fa-chevron-left"></i>
                </button>
              </Link>
              <h4> Measure Improvement Profile</h4>
            </div>

            <div className="before-profile-box">
              <div className="scrollContainer">
                <div className="row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 ">
                  {profile.map((item, i) => (
                    <a onClick={() => updateDetails(i)}>
                      <div className="option">
                        {/* <img className="scrollImage" src={item.image}     onError={event => {
                    event.target.src = "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg"
                    event.onerror = null
                  }}/> */}
                        <img
                          className={
                            i === currentIndex
                              ? "scrollImage active-img"
                              : "scrollImage"
                          }
                          src={item.process_image}
                          onError={(event) => {
                            event.target.src =
                              "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";
                            event.onerror = null;
                          }}
                        />
                      </div>
                    </a>
                  ))}
                </div>
              </div>
              {
                <div className="profileDetail">
                  <div className="toggle-div">
                    <button className="toggle-btn">
                      <input
                        type="checkbox"
                        id="toggle"
                        className="toggleCheckbox"
                        onChange={() => {
                          setShowGraph(!showGraph);
                          console.log(showGraph)
                        }}
                      />
                      <label for="toggle" className="toggleContainer">
                        {showGraph === true ? (
                          <div>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 31 31"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="30.1858"
                                height="30.1858"
                                rx="15.0929"
                                fill="white"
                              />
                              <g clip-path="url(#clip0_12_53)">
                                <path
                                  d="M15.4999 25C20.8536 25 25.2623 20.9903 25.9093 15.8125H15.2085L8.6106 22.4104C10.4547 24.0196 12.8605 25 15.4999 25Z"
                                  fill="#B07357"
                                />
                                <path
                                  d="M15.5 4V14.5H26C26 8.70137 21.2986 4 15.5 4Z"
                                  fill="#B07357"
                                />
                                <path
                                  d="M5 14.4999C5 17.1866 6.01719 19.6305 7.6775 21.4876L14.1875 14.9776V14.4999V4.09045C9.00969 4.73752 5 9.1462 5 14.4999Z"
                                  fill="#B07357"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_12_53">
                                  <rect
                                    width="21"
                                    height="21"
                                    fill="white"
                                    transform="translate(5 4)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        ) : (
                          <div>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 32 31"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_12_20)">
                                <path
                                  d="M16.023 30.952C23.7185 30.952 30.0556 25.1884 30.9857 17.7457H15.6041L6.12012 27.2297C8.77081 29.5427 12.229 30.952 16.023 30.952Z"
                                  fill="white"
                                />
                                <path
                                  d="M16.0227 0.766235V15.8592H31.1156C31.1156 7.52409 24.3578 0.766235 16.0227 0.766235Z"
                                  fill="white"
                                />
                                <path
                                  d="M0.929932 15.8592C0.929932 19.7211 2.39206 23.234 4.77863 25.9036L14.1362 16.546V15.8592V0.896484C6.69354 1.82659 0.929932 8.16372 0.929932 15.8592Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_12_20">
                                  <rect
                                    width="30.1858"
                                    height="30.1858"
                                    fill="white"
                                    transform="translate(0.929932 0.766235)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        )}
                        {showGraph === true ? (
                          <div>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 31 31"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                y="0.766235"
                                width="30.1858"
                                height="30.1858"
                                rx="15.0929"
                                fill="#C4C4C4"
                              />
                              <path
                                d="M15.093 15.8592C17.8715 15.8592 20.124 13.6067 20.124 10.8282C20.124 8.04968 17.8715 5.79724 15.093 5.79724C12.3145 5.79724 10.062 8.04968 10.062 10.8282C10.062 13.6067 12.3145 15.8592 15.093 15.8592Z"
                                fill="white"
                              />
                              <path
                                d="M25.1549 24.6634V25.9211C25.1549 26.2547 25.0224 26.5746 24.7865 26.8105C24.5506 27.0464 24.2307 27.1789 23.8972 27.1789H6.28875C5.95517 27.1789 5.63526 27.0464 5.39939 26.8105C5.16352 26.5746 5.03101 26.2547 5.03101 25.9211V24.6634C5.03101 22.662 5.82608 20.7425 7.24131 19.3273C8.65655 17.912 10.576 17.1169 12.5775 17.1169H17.6084C19.6099 17.1169 21.5294 17.912 22.9446 19.3273C24.3598 20.7425 25.1549 22.662 25.1549 24.6634Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        ) : (
                          <div>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 31 31"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="30.1858"
                                height="30.1858"
                                rx="15.0929"
                                fill="white"
                              />
                              <path
                                d="M15.093 15.093C17.8715 15.093 20.124 12.8405 20.124 10.062C20.124 7.28345 17.8715 5.03101 15.093 5.03101C12.3145 5.03101 10.062 7.28345 10.062 10.062C10.062 12.8405 12.3145 15.093 15.093 15.093Z"
                                fill="#B07357"
                              />
                              <path
                                d="M25.1549 23.8972V25.1549C25.1549 25.4885 25.0224 25.8084 24.7865 26.0443C24.5506 26.2801 24.2307 26.4127 23.8972 26.4127H6.28875C5.95517 26.4127 5.63526 26.2801 5.39939 26.0443C5.16352 25.8084 5.03101 25.4885 5.03101 25.1549V23.8972C5.03101 21.8957 5.82608 19.9763 7.24131 18.561C8.65655 17.1458 10.576 16.3507 12.5775 16.3507H17.6084C19.6099 16.3507 21.5294 17.1458 22.9446 18.561C24.3598 19.9763 25.1549 21.8957 25.1549 23.8972Z"
                                fill="#B07357"
                              />
                            </svg>
                          </div>
                        )}
                      </label>
                    </button>
                  </div>
                  {showGraph? (
                    <div className="graphBox row-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-3">
                      {Object.keys(graph).map((item, i) => {
                        if (graph[item] > 0)
                          return (
                            <div className="graph">
                              {" "}
                              <CircularProgress
                              sx={{ '--CircularProgress-size': '80px' , 'color': '#3FC1C9' , '--CircularProgress-progressColor': '#3FC1C9' }}
                                size="lg"
                                determinate
                                value={graph[item] * 100}
                              >
                                {graph[item] * 100}
                                {"%"}
                              </CircularProgress>
                              <h6>{item}</h6>{" "}
                            </div>
                          );
                      })}
                    </div>
                  ) : (
                    <div>
                      <div className="imgContainer">
                        <div className="div-center">
                          <span>Before</span>
                          <img
                            src={
                              profileDetail.beforeImage === ""
                                ? maskImage
                                : profileDetail.beforeImage
                            }
                            alt="beforeImage"
                            className="user-photo"
                            onClick={() => {
                              setImageDisplay(true);
                            }}
                            onError={(event) => {
                              event.target.src =
                                "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";
                              event.onerror = null;
                            }}
                          />
                        </div>
                        <div className="div-center">
                          <span>After</span>
                          <img
                            src={
                              profileDetail.afterImage === ""
                                ? maskImage
                                : profileDetail.afterImage
                            }
                            alt="afterImage"
                            className="user-photo"
                            onClick={() => {
                              setImageDisplay(true);
                            }}
                            onError={(event) => {
                              event.target.src =
                                "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";
                              event.onerror = null;
                            }}
                          />
                        </div>
                      </div>
                      <div className="photo-description">
                        <div className="description row">
                          <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                            <span>Patient Name</span>
                          </div>
                          <div className="description-data  col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1 ">
                            <span>{profileDetail.name}</span>
                          </div>
                        </div>

                        <div className="description row">
                          <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                            <span>Age</span>
                          </div>
                          <div className="description-data  col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                            <span>{profileDetail.age}</span>
                          </div>
                        </div>

                        <div className="description row">
                          <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                            <span>Image Upload On</span>
                          </div>
                          <div className="description-data  col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                            <span>{profileDetail.uploadDate}</span>
                          </div>
                        </div>

                        <div className="description row">
                          <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                            <span>Image Processed On</span>
                          </div>
                          <div className="description-data  col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                            <span>{profileDetail.processDate}</span>
                          </div>
                        </div>

                        <div className="description row">
                          <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                            <span>Image Purpose</span>
                          </div>
                          <div className="description-data col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1 ">
                            <span>Before After</span>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  )}
                </div>
}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MeasureProfile;
