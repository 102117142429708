import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import maskImage from "../../images/Mask Group.png";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import './swiperImage.css';

// import required modules
import { Navigation } from 'swiper/modules';

export default function SwiperImage({images, handleClose}) {
  // var images = [maskImage, maskImage]
  return (
    <>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
       { images.map((item, i) => (
         
         <SwiperSlide>
        <img
              src={item}
              alt="afterImage"
              onError={event => {
                event.target.src = "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg"
                event.onerror = null
              }}
              />
              <button id="close-button" onClick={() => handleClose()}>X</button>
        </SwiperSlide>
       ))}
      </Swiper>
    </>
  );
}
