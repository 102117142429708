import { Route, Routes } from "react-router-dom";
import RequiredAuth from "./components/requiredauth/RequiredAuth";
import Datereport from "./pages/data-report/Datereport";
import Login from "./pages/login/Login";
import Pagenotfound from "./pages/page-not-found/Pagenotfound";
import Trasferpatientdata from "./pages/trasfer-patient-data/Trasferpatientdata";
import PatientProfile from "./pages/pateint-profile/PatientProfile";
import Clinicmaping from "./pages/clinic-maping/Clinicmaping";
import Emailactivation from "./pages/email-activation/Emailactivation";
import Diagnosis from "./pages/Diagnosis-page/Diagnosis";
import Before from "./pages/Befor-page/Beforeafter";
import Patient from "./pages/Patient-page/Patient";
import Doctor from "./pages/Doctor-page/Doctor";
import DiagnosisProfile from "./pages/diagnosis-profile/DiagnosiProfile";
import ChangePassword from "./pages/Change-password/ChangePassword";
import MajorImprovement from "./pages/Measure-Improvement/MeasureImprovement";
import DoctorProfile from "./pages/doctor-profile/DoctorProfile";
import BeforeProfile from "./pages/before-profile/BeforeProfile";
import MeasureProfile from "./pages/measure-profile/MeasureProfile";
import AntiAging from "./pages/anti-aging/AntiAging";
import AntiAgingProfile from "./pages/antiaging-profile/AntiAgingProfile";

function App() {
  return (

    <Routes>
      <Route element={<RequiredAuth />}>
        <Route path="/" element={<Datereport />} />
        <Route path="/trasfer-patient-data" element={<Trasferpatientdata />} />
        <Route path="/diagnosis" element={<Diagnosis />} />
        <Route path="/before" element={<Before />} />
        <Route path="/anti-aging" element={<AntiAging/>} />
        <Route path="/antiaging-profile/:id" element={<AntiAgingProfile/>}/>
        <Route path="/before-profile/:id" element={<BeforeProfile/>}/> 
        <Route path="/patient" element={<Patient />} />
        <Route path="/pateint-profile/" element={<PatientProfile/>} />
        <Route path="/doctor" element={<Doctor />} />
        <Route path="/doctor-profile" element={<DoctorProfile/>}/>
        <Route path="/diagnosis-profile/:id" element={<DiagnosisProfile />} />
         <Route path="/email-activation" element={<Emailactivation />} /> 
         <Route path="/clinic-maping" element={<Clinicmaping />} /> 
         <Route path="change-password" element={<ChangePassword />} />
         <Route path="measure-improvement" element={<MajorImprovement />} />
         <Route path="measure-improvement-profile/:id" element={<MeasureProfile/>}/>
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Pagenotfound />} />
    </Routes>
  );
}

export default App;
