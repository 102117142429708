import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: [],
    isAuthorized: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setIsAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
    },
  },
});
export const { setUser, setIsAuthorized } = userSlice.actions;
export default userSlice.reducer;

export const UserToken = (state) => state.user.user.auth_token;
