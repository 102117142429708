import { useDispatch, useSelector } from "react-redux";
import { setIsSidebarOpen } from "../../redux/sidebarRedux";
import "./sidebarhum.scss";

const Sidebarhum = () => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state) => state.sidebar.isSidebarOpen);
  return (
    <div className="sidebarhum">
      <button onClick={() => dispatch(setIsSidebarOpen(!isSidebarOpen))}>
        {isSidebarOpen ? (
          <img src={process.env.PUBLIC_URL + "/images/close-icon.png"} alt="" />
        ) : (
          <img
            src={process.env.PUBLIC_URL + "/images/Hamburger_icon.png"}
            alt=""
          />
        )}
      </button>
    </div>
  );
};

export default Sidebarhum;
