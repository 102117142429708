import "./beforeAfterItem.scss";
import { Link } from "react-router-dom";

const BeforeAfterItem = ({ index, record }) => {
  return (
    <div className="before-card">
      <div className="heading-title">
        <h3 className="name">{record.patient.patient_name}</h3>
        <h3>{record.created_date}</h3>
      </div>
      <div className="photo-container">
        <img
          src={record.image}
          alt="Photo 1"
          className="photo"
          onError={(event) => {
            event.target.src =
              "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";
            event.onerror = null;
          }}
        />
        <img
          src={record.process_image}
          alt="Photo 2"
          className="photo"
          onError={(event) => {
            event.target.src =
              "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";
            event.onerror = null;
          }}
        />
      </div>
      <Link to={`/before-profile/${record.patient.id}`}>
        <button className=" btn-view-profile">View</button>
      </Link>
    </div>
  );
};

export default BeforeAfterItem;
