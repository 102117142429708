import "./pageNav.scss";
import React, { useState, useEffect } from "react";

export default function PageNav({ index, currentPage, TotalPage, getData }) {
  const [buttons, setButtons] = useState([]);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(10);
  // 10>TotalPage? setEnd(TotalPage): setEnd(10);// tatal page 4100 en
  const [isLoading, setIsLoading] = useState(false);
  //console.log(TotalPage)
  function handleNext() {
    if (currentPage === end && end<TotalPage) {
      currentPage++;
      setStart(start+1);
      setEnd(end+1);
    } else {
      currentPage++;
    }
    getData(currentPage);
  }

  function handlePrev() {
    if (currentPage === start && start>1) {
      currentPage--;
      setStart(start-1);
      setEnd(end-1);
    } else {
      if(currentPage>1)
      currentPage--;
    }
    getData(currentPage);
  }
  
  
  useEffect(() => {
    const generateButtons = () => {
      setIsLoading(true);
      let content = [];
      for (var i = start; i <= end; i++) {
        if(i>TotalPage){ break;}
        content.push(i);
      }
      // if (TotalPage <= 10) {
      //   setEnd(TotalPage);
      // }
      setButtons(content);
      setIsLoading(false);
      //console.log("start",start)
      //console.log("end", end)
      //console.log(content)
    };
    
    generateButtons();
  }, [start, end, TotalPage]);

  return (
    TotalPage === 0? <div></div>:
    <>
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <button
            className="nav-item"
            disabled={currentPage===1}
            onClick={() => {
              handlePrev();
            }}
          >
            {" "}
            <span aria-hidden="true">&laquo;</span>
          </button>
          {buttons.map((item, i) => (
            <button
              key={item}
              className={item === currentPage ? "nav-item nav-active" : "nav-item"}
              onClick={() => getData(item)}
            >
              {item}
            </button>
          ))}
          <button
            className="nav-item"
            disabled={currentPage===TotalPage}
            onClick={() => {
              handleNext();
            }}
          >
            {" "}
            <span aria-hidden="true">&raquo;</span>
          </button>
        </ul>
      </nav>
    </>
  );
}
