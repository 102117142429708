import React from "react";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";

const Emailactivation = () => {
  return (
<>
    <div className="main-container">
      <h1>Email Activation</h1>
      </div>
    </>
  );
};

export default Emailactivation;
