// import React from 'react'

import React, { useState, useEffect } from "react";
import "./beforeProfile.scss";
import { beforeAfterId } from "../../apiCalls";
import { useParams, Link } from "react-router-dom";
import PageNav from "../../components/pageNav/PageNav";
import maskImage from "../../images/Mask Group.png";
import maskphoto from "../../images/Mask Group.png";
import { UserToken } from "../../redux/userRedux";
import { useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import SwiperImage from "../../components/image-slider/SwiperImage";

const BeforeProfile = () => {
  const [profile, setProfile] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const initialValues = {
    name: "-",
    age: "-",
    uploadDate: "-",
    processDate: "-",
    beforeImage: "",
    afterImage: "",
  };
  const [profileDetail, setProfileDetail] = useState(initialValues);
  var res;
  const { id } = useParams();
  const userToken = useSelector(UserToken);
  const [isLoading, setIsLoading] = useState(false);
  const [displayImage, setImageDisplay] = useState(false);

  const updateDetails = (index) => {
    setCurrentIndex(index);
    setProfileDetail({
      ...profileDetail,
      name: profile[index].patient.patient_name,
      age: profile[index].patient.age,
      beforeImage: profile[index].image,
      afterImage: profile[index].process_image,
      processDate: profile[index].updated_date,
      uploadDate: profile[index].created_date,
    });
  };

  const getData = async (page) => {
    try {
      setIsLoading(true);
      res = await beforeAfterId(userToken, id);
      setProfile(res.results);

      setProfileDetail({
        ...profileDetail,
        name: res.results[0].patient.patient_name,
        age: res.results[0].patient.age,
        beforeImage: res.results[0].image,
        afterImage: res.results[0].process_image,
        processDate: res.results[0].updated_date,
        uploadDate: res.results[0].created_date,
      });

      setCurrentPage(page);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setImageDisplay(false);
  };

  useEffect(() => {
    getData(1);
  }, []);

  return (
    <>
      <div className="main-container">
        {isLoading === true ? (
          <Loader />
        ) : (
          <div>
            {displayImage && (
              <SwiperImage
                images={[profileDetail.beforeImage, profileDetail.afterImage]}
                handleClose={handleClose}
              />
            )}
            <div id="pageTitle">
              <Link style={{ textDecoration: "none" }} to="/before">
                <button className="back-btn">
                  <i className="fa-solid fa-chevron-left"></i>
                </button>
              </Link>
              <h4> Before After Profile</h4>
            </div>
            <div className="before-profile-box">
              <div className="scrollContainer">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 ">
                  {profile.map((item, i) => (
                    <a onClick={() => updateDetails(i)}>
                      <div className="option">
                        {/* <img
                      className="scrollImage"
                      src={item.image}
                      onError={(event) => {
                        event.target.src =
                          "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";
                        event.onerror = null;
                      }}
                    /> */}
                        <img
                          className={
                            i === currentIndex
                              ? "scrollImage active-img"
                              : "scrollImage"
                          }
                          src={item.process_image}
                          onError={(event) => {
                            event.target.src =
                              "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";
                            event.onerror = null;
                          }}
                        />
                      </div>
                    </a>
                  ))}
                </div>
              </div>
              {
                <div className="profileDetail">
                  <div className="imgContainer">
                    <div className="div-center">
                      <span>Before</span>
                      <img
                        src={
                          profileDetail.beforeImage === ""
                            ? maskImage
                            : profileDetail.beforeImage
                        }
                        alt="beforeImage"
                        onClick={() => {
                          setImageDisplay(true);
                        }}
                        className="user-photo"
                        onError={(event) => {
                          event.target.src =
                            "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";
                          event.onerror = null;
                        }}
                      />
                    </div>
                    <div className="div-center">
                      <span>After</span>
                      <img
                        src={
                          profileDetail.afterImage === ""
                            ? maskImage
                            : profileDetail.afterImage
                        }
                        alt="afterImage"
                        onClick={() => {
                          setImageDisplay(true);
                        }}
                        className="user-photo"
                        onError={(event) => {
                          event.target.src =
                            "https://cdn.vectorstock.com/i/preview-1x/65/30/default-image-icon-missing-picture-page-vector-40546530.jpg";
                          event.onerror = null;
                        }}
                      />
                    </div>
                  </div>
                  <div className="photo-description">
                    <div className="description row">
                      <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                        <span>Patient Name</span>
                      </div>
                      <div className="description-data  col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1 ">
                        <span>{profileDetail.name}</span>
                      </div>
                    </div>

                    <div className="description row">
                      <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                        <span>Age</span>
                      </div>
                      <div className="description-data  col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                        <span>{profileDetail.age}</span>
                      </div>
                    </div>

                    <div className="description row">
                      <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                        <span>Image Upload On</span>
                      </div>
                      <div className="description-data  col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                        <span>{profileDetail.uploadDate}</span>
                      </div>
                    </div>

                    <div className="description row">
                      <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                        <span>Image Processed On</span>
                      </div>
                      <div className="description-data  col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                        <span>{profileDetail.processDate}</span>
                      </div>
                    </div>

                    <div className="description row">
                      <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1">
                        <span>Image Purpose</span>
                      </div>
                      <div className="description-data col-xl-6 col-lg-6 col-md-6 col-sm-1 col-12 p-1 ">
                        <span>Before After</span>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BeforeProfile;
