import axios from "axios";

const BASE_URL =  "http://face-ai-new.centralindia.cloudapp.azure.com:8001/kaya";

const loginPath = BASE_URL + "/login/";
const doctorTransferPath = BASE_URL + "/patient-transffer-to-other-doctor/";
const pateintReportDownloadPath = BASE_URL + "/api/v2/patient-report-date-wise/";
const beforeAfterPath = BASE_URL + "/api/v2/beforeafter-images/";
const antiAgePath = BASE_URL + "/api/v2/diagnosis-images/?anti_aging=1";
const diagnosisPath = BASE_URL + "/api/v2/diagnosis-images";
const diagnosisImagePath = BASE_URL + "/api/v2/diagnosis-images-patient";
const doctorPath = BASE_URL + "/api/v2/doctor/";
const imagesPath = BASE_URL + "/api/v2/images";
const updatePasswordPath = BASE_URL + "/api/v2/update-password"
const measureImprovementImagesPath = BASE_URL + "/api/v2/measureimprovement-images";
const patientPath = BASE_URL + "/api/v2/patient";
const filterPath = BASE_URL + "/api/v2/filter-diagnosis-before-after-measure-improvement-patient-image"
const adminDownloadPath = BASE_URL + "/api/v2/admin-report/"

const reportDownloadPath = BASE_URL + "/report-download"

export const loginUser = async (LoginCreds) => {
  try {
    const response = await axios.post(loginPath, LoginCreds);
    return response;
  } catch (error) {
    throw error;
  }
};

export const reportWithDate = async (auth, start_date, end_date) => {
  try {
    const response = await axios.get(
      pateintReportDownloadPath + `${start_date}/${end_date}/`,
      {
        headers: {
          'Authorization': `Token ${auth}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const doctorTranfer = async (auth, doctorData) => {
  try {
    const response = await axios.post(doctorTransferPath, doctorData, {
      headers: {
        'Authorization': `Token ${auth}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const beforeAfter = async (auth, query_param) => {
  
  try{
    const response = await axios.get(beforeAfterPath, {
      headers: {
        'Authorization': `Token ${auth}`,
      },
      params:query_param,
    } );
    return response.data;
  }catch(error){
    throw error;
  }
}

export const beforeAfterId = async (auth, id) => {
  try{
    var url = `${beforeAfterPath}${id}/`
    const response = await axios.get(url,{
      headers: {
        'Authorization': `Token ${auth}`,
      },
    } );
    return response.data;
  }catch(error){
    throw error;
  }
}

export const antiAgeImage = async (auth, query_param) => {
  try{
    const response = await axios.get(antiAgePath, {
      headers: {
        'Authorization': `Token ${auth}`,
      },
      params: query_param,
      // withCredentials: true
    } );
    return response.data;
  }catch(error){
    throw error;
  }
}

export const diagnosisImage = async (auth, query_param) => {
  try{
    const response = await axios.get(diagnosisPath, {
      headers: {
        'Authorization': `Token ${auth}`,
      },
      params: query_param,
      // withCredentials: true
    } );
    return response.data;
  }catch(error){
    throw error;
  }
}

export const diagnosisImageId = async (auth, page, anti_aging, id) => {
  try{
    //console.log(id)
    //console.log(page)
    const url = `${diagnosisImagePath}/${id}?page=${page}&anti_aging=${anti_aging}` 
    const response = await axios.get(url,{
      headers: {
        'Authorization': `Token ${auth}`,
      },
    } );
    //console.log(response.data);
    return response.data;
  }catch(error){
    throw error;
  }
}

export const doctor = async (auth, query_param) => {
  try{
    const response = await axios.get(doctorPath, {
      headers: {
        'Authorization': `Token ${auth}`,
      },
      params: query_param,
    } );
    return response.data;
  }catch(error){
    throw error;
  }
}

export const images = async (auth, page, query_param) => {
  try{
    const url = `${images}?page=${page}` 
    const response = await axios.get(beforeAfterPath, {
      headers: {
        'Authorization': `Token ${auth}`,
      },
    } );
    return response.data;
  }catch(error){
    throw error;
  }
}

export const imagesId = async (auth, id) => {
  try{
    const response = await axios.get(imagesPath, id ,{
      headers: {
        'Authorization': `Token ${auth}`,
      },
    } );
    return response.data;
  }catch(error){
    throw error;
  }
}

export const  measureImprovementImages = async (auth, query_param) => {
  try{
    const response = await axios.get(measureImprovementImagesPath, {
      headers: {
        'Authorization': `Token ${auth}`,
      },
      params: query_param,
    } );
    return response.data;
  }catch(error){
    throw error;
  }
}

export const  measureImprovementImagesId = async (auth, id) => {
  try{
    var url = `${measureImprovementImagesPath}/${id}/`

    const response = await axios.get(url, {
      headers: {
        'Authorization': `Token ${auth}`,
      },
    } );
    return response.data;
  }catch(error){
    throw error;
  }
}

export const reportDownload  = async (auth, id) => {
  try{
    const response = await axios.get(reportDownloadPath, id, {
      headers: {
        'Authorization': `Token ${auth}`,
      },
    } );
    return response.data;
  }catch(error){
    throw error;
  }
}

export const addDoctor = async (auth, data) => {
try{
  const response = await axios.post(doctorPath, {
    last_login: data.last_login,
  is_superuser: data.is_superuser,
  email: data.email,
  username: data.username,
  fname: data.fname,
  lname: data.lname,
  password: data.password,
  is_active: data.is_active,
  is_staff: data.is_staff,
  clinic_name: data.clinic_name,
  clinic_location: data.clinic_location,
  clinic_email: data.clinic_email,
  groups: data.groups,
  user_permissions: data.user_permissions
  })}
  catch(error){
    throw(error)
  }
} 

export const updateDoctor = async (auth,id, data) => {
  try{
    var url = `${doctorPath}/${id}/`
    const response = await axios.patch(url, data, {      headers: {
      'Authorization': `Token ${auth}`,
    },})
    return response
  }
    catch(error){
      throw(error)
    }
  } 

  export const patient = async (auth, query_param) => {
    try{

      const response = await axios.get(patientPath, {
        headers: {
          'Authorization': `Token ${auth}`,
        },
        params: query_param,
      } );
      //console.log(response);
      return response.data;
    }catch(error){
      throw error;
    }
  }


  export const adminDownload = async (auth, query_param) => {
    try{
      const response = await axios.get(adminDownloadPath, {
        headers: {
          'Authorization': `Token ${auth}`,
        },
        params: query_param,
      } );
      //console.log(response);
      return response.data;
    }catch(error){
      throw error;
    }
  }

  export const updatePatient = async (auth, id, data) => {
    try{
      var url = `${patientPath}/${id}/`
      const response = await axios.patch(url, data,           {
        headers: {
          'Authorization': `Token ${auth}`,
        },})
      return response
    }
    catch(error){
      throw(error)
    }
  }

  export const updatePassword = async (auth, id, passwordData) => {
    try {
      let url = `${updatePasswordPath}/${id}/`
      const response = await axios.patch(url,  passwordData, {
        headers: {
          'Authorization': `Token ${auth}`,
        }});
      return response.data;
    } catch (error) {
      throw error;
    }
  };
