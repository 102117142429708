import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./patientProfile.scss";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserToken } from "../../redux/userRedux";
import { toast, Toaster } from "react-hot-toast";
import maskImage from "../../images/Mask Group.png";
import { updatePatient } from "../../apiCalls/index";
import Loader from "../../components/loader/Loader";


const PatientProfile = () => {
  const userToken = useSelector(UserToken);
  const location = useLocation();
  const { info } = location.state || {};
  const [page, setPage] = useState("Patient")
  const [edit, setEdit] = useState(0);
  const [name, setName] = useState(info.patient_name);
  const [email, setEmail] = useState(info.patient_email);
  const [contact, setContact] = useState(info.phone);
  const [gender, setGender] = useState(info.gender);
  const [register, setRegister] = useState(info.regi_date);
  const [dob, setDob] = useState(info.dob);
  const [clinicName, setClinicName] = useState(info.doctor.clinic_name);
  const [bloodGroup, setBloodGroup] = useState("blood");
  const [heartBeat, setHeartBeat] = useState(info.heartbeat);
  const [bloodPressure, setBloodPressure] = useState(info.bp);
  const [dustAllergy, setDustAllergy] = useState(info.dust_allergy);
  const [medical, setMedical] = useState(info.medication);
  const [isLoading, setIsLoading] = useState(false);


  //console.log(info);

  const handleSubmit = async () => {
    try {
    setIsLoading(true);
      var post = {
        patient_name: name,
        patient_email: email,
        phone: contact,
        gender: gender,
        regi_date: register,
        dob: dob,
        clinic_name : clinicName,
        heartbeat: heartBeat,
        bp: bloodPressure,
        dust_allergy: dustAllergy,
        medication: medical        
      };
      //console.log(info.id);
      await updatePatient(userToken, info.id, post);
      //console.log("updated", info.id);
      setEdit(true)
      toast.success("Successfully Updated");
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong");
        setIsLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    setEdit(1);
  }, []);
  return (
    <>
      <div className="main-container">
      <div className="align-start-div">
      <div id="pageTitle">
            <Link style={{ textDecoration: "none" }} to="/patient">
            <button className="back-btn">
            <i className="fa-solid fa-chevron-left"></i>
            </button>
            </Link>
            <h4>Patient Profile</h4>
          </div>
          </div>
      <div className="doctor-profile-box">
          <div className="formNav ">
            <img src={maskImage} alt="profilePic" />
            <button className= {page ===  "Patient" ? "selected pageOption" : "pageOption"} onClick={()=> setPage("Patient")}>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="14.5" cy="15" r="14.5" fill="#1F1F1F" />
                <path
                  d="M21.4384 18.0733C20.3695 16.9309 19.0974 16.0851 17.7093 15.5732C19.196 14.4787 20.1726 12.647 20.1726 10.5759C20.1726 7.23211 17.6276 4.51172 14.4994 4.51172C11.3712 4.51172 8.82619 7.23211 8.82619 10.5759C8.82619 12.647 9.80287 14.4787 11.2895 15.5732C9.9015 16.0851 8.62932 16.9309 7.56049 18.0733C5.70704 20.0545 4.68628 22.6886 4.68628 25.4904H6.21958C6.21958 20.6103 9.93389 16.64 14.4994 16.64C19.0649 16.64 22.7792 20.6103 22.7792 25.4904H24.3125C24.3125 22.6886 23.2918 20.0545 21.4384 18.0733ZM14.4994 15.0011C12.2167 15.0011 10.3595 13.016 10.3595 10.5759C10.3595 8.13579 12.2167 6.15068 14.4994 6.15068C16.7822 6.15068 18.6393 8.13579 18.6393 10.5759C18.6393 13.016 16.7822 15.0011 14.4994 15.0011Z"
                  fill="white"
                />
              </svg>
              Patient Details
            </button>
            <button className= {page ===  "Medical" ? "selected pageOption" : "pageOption"} onClick={()=> setPage("Medical")}>
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="14.5" cy="15" r="14.5" fill="#1F1F1F" />
                <g clip-path="url(#clip0_825_3454)">
                  <path
                    d="M20.8327 11.1945C19.3754 11.1945 18.1938 12.3761 18.1938 13.8334C18.1938 14.9167 18.8473 15.8449 19.7808 16.2516V18.8477C19.7808 20.7361 18.1233 22.2782 16.0864 22.2782C14.1072 22.2782 12.481 20.8235 12.3952 19.005C14.7666 18.5109 16.6105 16.3833 16.6105 13.8037V8.70706C16.6105 8.33168 16.3417 8.00182 15.9745 7.93189L13.8991 7.51585C13.4702 7.42926 13.0566 7.70605 12.9702 8.135L12.8633 8.65429C12.7767 9.0831 13.055 9.4984 13.4838 9.58515L14.4961 9.78502V13.7906C14.4961 15.5349 13.1064 16.978 11.3581 16.9945C9.59734 17.011 8.16475 15.5906 8.16475 13.8301L8.16244 9.78835L9.17578 9.58674C9.6046 9.50427 9.88201 9.087 9.79526 8.65818L9.69333 8.13904C9.61086 7.71022 9.19359 7.43281 8.76477 7.51956L6.69093 7.92859C6.3238 8.00281 6.05496 8.32772 6.05496 8.70706V13.8331C6.05496 16.3813 7.87283 18.5171 10.2805 19.0053C10.3662 21.9909 12.9359 24.389 16.0827 24.389C19.2824 24.389 21.8883 21.9028 21.8883 18.8473V16.2513C22.8195 15.8436 23.4716 14.9167 23.4716 13.8351C23.4716 12.3754 22.2907 11.1945 20.8327 11.1945ZM20.8327 14.6251C20.3957 14.6251 20.0411 14.2705 20.0411 13.8334C20.0411 13.3964 20.394 13.0418 20.8327 13.0418C21.2715 13.0418 21.6244 13.3947 21.6244 13.8334C21.6244 14.2722 21.2715 14.6251 20.8327 14.6251Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_825_3454">
                    <rect
                      width="19"
                      height="16.8889"
                      fill="white"
                      transform="translate(5 7.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Medical Details
            </button>
            {/* <div className="pageOption">
              <svg
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="14.5" cy="15" r="14.5" fill="#1F1F1F" />
                <g clip-path="url(#clip0_825_3462)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.1795 16.2C19.1795 15.99 19.2495 15.78 19.2495 15.5C19.2495 15.22 19.2495 15.01 19.1795 14.8L20.6495 13.61C20.7895 13.47 20.7895 13.33 20.7195 13.19L19.3195 10.74C19.2495 10.67 19.1095 10.6 18.8995 10.67L17.1495 11.37C16.7995 11.09 16.3795 10.88 15.9595 10.67L15.6795 8.85C15.7495 8.64 15.5395 8.5 15.3995 8.5H12.5995C12.4595 8.5 12.2495 8.64 12.2495 8.78L11.9695 10.67C11.5495 10.81 11.1995 11.09 10.7795 11.37L9.0995 10.67C8.8895 10.6 8.7495 10.67 8.6095 10.81L7.2095 13.26C7.1395 13.33 7.2095 13.54 7.3495 13.68L8.8195 14.8C8.8195 15.01 8.7495 15.22 8.7495 15.5C8.7495 15.78 8.7495 15.99 8.8195 16.2L7.3495 17.39C7.2095 17.53 7.2095 17.67 7.2795 17.81L8.6795 20.26C8.7495 20.33 8.8895 20.4 9.0995 20.33L10.8495 19.63C11.1995 19.91 11.6195 20.12 12.0395 20.33L12.3195 22.15C12.3195 22.29 12.4595 22.43 12.6695 22.43H15.4695C15.6095 22.43 15.8195 22.29 15.8195 22.15L16.0995 20.33C16.5195 20.12 16.9395 19.91 17.2895 19.63L19.0395 20.33C19.1795 20.4 19.3895 20.33 19.4595 20.19L20.8595 17.74C20.9295 17.6 20.9295 17.39 20.7895 17.32L19.1795 16.2ZM13.9995 17.95C12.6695 17.95 11.5495 16.83 11.5495 15.5C11.5495 14.17 12.6695 13.05 13.9995 13.05C15.3295 13.05 16.4495 14.17 16.4495 15.5C16.4495 16.83 15.3295 17.95 13.9995 17.95Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_825_3462">
                    <rect
                      width="14"
                      height="14"
                      fill="white"
                      transform="translate(7 8.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Settings
            </div> */}
          </div>
            <div className="row">
           {
           page ===  "Patient" ? 
            (
     <div className="form row row-xl-6 row-lg-6 row-md-10 row-sm-10 row-10 ">
              <div className="formHeading row-xl-1 row-lg-1 row-md-1 row-sm-1 row-10 m-3">
                <h2> {info.patient_name}</h2>
                {/* <button className="edit btn" onClick={() => setEdit(!edit)}>
                  Edit Info
                </button> */}
              </div>
              
              <div className="row row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-sm-1 ">
                <div className="form-group">
                  <label for="name">Patient Name</label>
                  <input
                    type="text"
                    id="name"
                    className="form-control search-box p-3"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled={edit}
                  />
                </div>
                <div className="form-group">
                  <label for="email">Patient Email</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control search-box p-3"
                    value={email}
                    disabled={edit}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="row row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-sm-1 ">
                <div className="form-group">
                  <label for="contact">Mobile Number</label>
                  <input
                    type="text"
                    id="contact"
                    className="form-control search-box p-3"
                    value={contact}
                    disabled={edit}
                    onChange={(e) => setContact(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label for="registration">Registration Date</label>
                  <input
                    type="date"
                    id="registration"
                    className="form-control search-box p-3"
                    disabled={edit}
                    value={register}
                    // onChange={(e) => setEmail(e.target.value())}
                  />
                </div>
              </div>
              <div className="row row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-sm-1 ">
                <div className="form-group">
                  <label for="dob">Date of Birth</label>
                  <input
                    type="date"
                    id="dob"
                    className="form-control search-box p-3"
                    disabled={edit}
                    value={dob}
                    // onChange={(e) => setName(e.target.value())}
                  />
                </div>
                <div className="form-group">
                  <label for="gender">Gender</label>
                  <input
                    type="text"
                    id="gender"
                    className="form-control search-box p-3"
                    value={gender}
                    disabled={edit}
                    onChange={(e) => setGender(e.target.value)}
                  />
                </div>
              </div>
              {/* <button
                className="submit btn m-3 p-2"
                disabled={edit}
                onClick={() => handleSubmit()}
              >
                                              {isLoading === true? (
                    <svg
                    className="spinner" 
                      width="20"
                      height="20"
                      viewBox="0 0 13 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.38798 12.616C3.36313 12.2306 2.46328 11.5721 1.78592 10.7118C1.10856 9.85153 0.679515 8.82231 0.545268 7.73564C0.411022 6.64897 0.576691 5.54628 1.02433 4.54704C1.47197 3.54779 2.1845 2.69009 3.08475 2.06684C3.98499 1.4436 5.03862 1.07858 6.13148 1.01133C7.22435 0.944078 8.31478 1.17716 9.28464 1.68533C10.2545 2.19349 11.0668 2.95736 11.6336 3.89419C12.2004 4.83101 12.5 5.90507 12.5 7"
                        stroke="white"
                      />
                    </svg>
                  ) : (
                    "Save"
                  )}
              </button> */}
            </div>
            ) : 
            (
            <div className="form row row-xl-6 row-lg-6 row-md-6 row-sm-10 row-10 ">
              <div className="formHeading row-xl-1 row-lg-1 row-md-1 row-sm-1 row-10 m-3">
                <h2> {info.patient_name}</h2>
                {/* <button className="edit btn" onClick={() => setEdit(!edit)}>
                  Edit Info
                </button> */}
              </div>
              <div className="row">
                <div className="form-group">
                  <label for="name">Clinic Name</label>
                  <input
                    type="text"
                    id="clinic_name"
                    className="form-control search-box p-3"
                    value={clinicName}
                    onChange={(e) => setClinicName(e.target.value)}
                    disabled={edit}
                  />
                </div>
                {/* <div className="form-group">
                  <label for="email">Blood Group</label>
                  <input
                    type="text"
                    id="blood_group"
                    className="form-control search-box p-3"
                    value={bloodGroup}
                    disabled={edit}
                    onChange={(e) => setBloodGroup(e.target.value)}
                  />
                </div> */}
              </div>
              <div className="row row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-sm-1 ">

                <div className="form-group">
                  <label for="contact">Heart Beat</label>
                  <input
                    type="text"
                    id="heart_beat"
                    className="form-control search-box p-3"
                    value={heartBeat}
                    disabled={edit}
                    onChange={(e) => setHeartBeat(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label for="registration">Blood Pressure</label>
                  <input
                    type="text"
                    id="blood_pressure"
                    className="form-control search-box p-3"
                    disabled={edit}
                    value={bloodPressure}
                    onChange={(e) => setBloodPressure(e.target.value())}
                  />
                </div>
              </div>
              <div className="row row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-sm-1 ">
                <div className="form-group">
                  <label for="dob">Dust Allergy</label>
                  <input
                    type="text"
                    id="dust"
                    className="form-control search-box p-3"
                    disabled={edit}
                    value={dustAllergy}
                    onChange={(e) => setDustAllergy(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label for="gender">Medical Service</label>
                  <input
                    type="text"
                    id="medical"
                    className="form-control search-box p-3"
                    value={medical}
                    disabled={edit}
                    onChange={(e) => setMedical(e.target.value)}
                  />
                </div>
              </div>
              {/* <button
                className="submit btn m-3 p-2"
                disabled={edit}
                onClick={() => handleSubmit()}
              >
                              {isLoading === true? (
                    <svg
                    className="spinner" 
                      width="20"
                      height="20"
                      viewBox="0 0 13 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.38798 12.616C3.36313 12.2306 2.46328 11.5721 1.78592 10.7118C1.10856 9.85153 0.679515 8.82231 0.545268 7.73564C0.411022 6.64897 0.576691 5.54628 1.02433 4.54704C1.47197 3.54779 2.1845 2.69009 3.08475 2.06684C3.98499 1.4436 5.03862 1.07858 6.13148 1.01133C7.22435 0.944078 8.31478 1.17716 9.28464 1.68533C10.2545 2.19349 11.0668 2.95736 11.6336 3.89419C12.2004 4.83101 12.5 5.90507 12.5 7"
                        stroke="white"
                      />
                    </svg>
                  ) : (
                    "Save"
                  )}
              </button> */}
            </div>
            )
            }
     </div>
        </div>
      </div>
      <Toaster position="bottom-right" reverseOrder={false} />
    </>
  );
};

export default PatientProfile;
