import "./infoTable.css"

const InfoTable = ({index, records}) => {
    return (
        <>
        {records.map((item, i) => {
            var name = `Dr.${item.fname} ${item.lname}`;
            var email= item.clinic_email === null ? "-" : item.clinic_email;
            // id, guest code , client name, doctor name , clinic email , flow Completed, date, service name, service id, created date, updated date 
            
            return(
            <tr className="record">
            <td>{item['Guest Code']}</td>
            <td>{item['Client Name']}</td>
            <td>{item['Doctor Name']}</td>
            <td>{item['Date']}</td>
            <td>{item['Service Name']}</td>
            <td>{item['Service Id']}</td>
            <td>{item['Flow Completed']}</td>

            {/* <td>{item['Skintype edited']}</td> */}
            </tr>)})}
        </>
    );
}

export default InfoTable;