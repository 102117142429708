import "./header.scss";
import Menu from "./menu/Menu";
// import Searchbar from "./searchbar/Searchbar";
import Sidebarhum from "../sidebar-ham/Sidebarhum";

const Header = () => {
  return (
    <div className="header">
      {/* <Searchbar/> */}
      <div className="logo-container">
        <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="logo" />
      </div>
      <Sidebarhum/>
      <Menu />
    </div>
  );
};

export default Header;
