import React from "react";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";

const Clinicmaping = () => {
  return (
<>
    <Header />
      <Sidebar />
    <div className="main-container">
      <h1>Client Mapping</h1>
      </div>
    </>
  );
};

export default Clinicmaping;
