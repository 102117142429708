import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { loginUser } from "../../apiCalls/index";
import { setIsAuthorized, setUser } from "../../redux/userRedux";
import "./login.scss";

const Login = () => {
  const dispatch = useDispatch();
  const initialValues = { username: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const isAuthorized = useSelector((state) => state.user.isAuthorized);
  const [formErrors, setFormErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser({ ...formValues })
      .then((response) => {
        toast.success("Successfully logged");
        dispatch(setUser(response.data));
        dispatch(setIsAuthorized(true));
      })
      .catch((error) => {
        toast.error("Somthing went wrong");
        setFormErrors(error.response.data);
        throw error;
      });
  };

  if (isAuthorized) {
    return <Navigate to="/" />;
  }
  return (
    <div className="login">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="input-container">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            placeholder="username"
            name="username"
            id="username"
            className={formErrors?.message ? "error" : undefined}
            onChange={(e) => handleChange(e)}
            required
          />
        </div>
        <div className="input-container">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            placeholder="password"
            name="password"
            id="password"
            className={formErrors.message ? "error" : undefined}
            onChange={(e) => handleChange(e)}
            required
          />
        </div>
        {formErrors.message && <span>{formErrors.message}</span>}

        <button type="submit">Login</button>
      </form>
      <Toaster position="bottom-right" reverseOrder={false} />
    </div>
  );
};

export default Login;
