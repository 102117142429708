import "./infoTable.css"
import { Link } from "react-router-dom";

const InfoTable = ({index, records}) => {
    return (
        <>
        {records.map((item, i) => {
            var name = `Dr.${item.fname} ${item.lname}`;
            var email = item.email === null ? "-" : item.email;
            var cemail= item.clinic_email === null ? "-" : item.clinic_email;
            var cname= item.clinic_name === null ? "-" : item.clinic_name;
            const dateObject = new Date(item.last_login);
            // const formattedDate = dateObject.toLocaleString(); 
            
            return(
            <tr className="record">
                                <td><svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="28.0736" cy="28.0736" r="28.0736" fill="white"/>
<path d="M41.5722 33.9935C39.4929 31.7921 37.0181 30.1623 34.3179 29.1757C37.2099 27.0667 39.1099 23.5371 39.1099 19.546C39.1099 13.1025 34.1589 7.86035 28.0734 7.86035C21.988 7.86035 17.037 13.1025 17.037 19.546C17.037 23.5371 18.937 27.0667 21.8291 29.1757C19.1289 30.1623 16.654 31.7921 14.5748 33.9935C10.9691 37.8113 8.9834 42.8872 8.9834 48.2863H11.9662C11.9662 38.8823 19.1919 31.2316 28.0734 31.2316C36.955 31.2316 44.1807 38.8823 44.1807 48.2863H47.1635C47.1635 42.8872 45.1777 37.8113 41.5722 33.9935ZM28.0734 28.0733C23.6327 28.0733 20.0198 24.248 20.0198 19.546C20.0198 14.8439 23.6327 11.0186 28.0734 11.0186C32.5142 11.0186 36.1271 14.8439 36.1271 19.546C36.1271 24.248 32.5142 28.0733 28.0734 28.0733Z" fill="#2B2B2B"/>
</svg>
</td>
            <td>{name}</td>
            <td className="nameTd">{cname}</td>
            <td>{email}</td>
            {/* <td>{cemail}</td> */}
            {/* <td>{formattedDate}</td> */}
            <td>{item.id}</td>
            <div className="btn-div">
            <Link to = "/doctor-profile/" state={{ info: item }}>
            <button className="infoButton"> 
            <i className="fa-solid fa-chevron-right  infoIcon"></i>
</button>
</Link>
</div>
            </tr>)})}
        </>
    );
}

export default InfoTable;