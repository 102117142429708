// import React from 'react'

import React, { useState, useEffect, useRef } from "react";
import "./diagnosisProfile.scss";
import "../../css/profile.scss";
import { diagnosisImageId } from "../../apiCalls";
import { useParams, Link } from "react-router-dom";
import PageNav from "../../components/pageNav/PageNav";
import maskphoto from "../../images/Mask Group.png";
import { UserToken } from "../../redux/userRedux";
import { useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import SwiperImage from "../../components/image-slider/SwiperImage"
import moment from "moment/moment";

const DiagnosisProfile = () => {
  const profile = useRef([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const initialValues = {name: "-", age: "-", uploadDate: "-", processDate: "-", image: "", graph: ""};
  const [profileDetail, setProfileDetail] = useState(initialValues);
  var res;
  const [totalPage, setTotalPage] = useState(0);
  const { id } = useParams();
  const userToken = useSelector(UserToken);
  // const [slider, setSlider] = useState("Diagnosis");
  const [isLoading, setIsLoading] = useState(false);
  const [showGraph, setShowGraph] = useState(true)
  const [displayImage, setImageDisplay] = useState(false) 
  const [largeImage, setLargeImage] = useState('')

  const updateDetails = (index) => {
    setCurrentIndex(index);
    
    var date = new moment(profile.current[index].updated_date);
    date = date.subtract(10, 'seconds');
    date = moment(date).format('DD MMM YYYY HH:mm:ss');

    setProfileDetail({
      ...profileDetail,
      name: profile.current[index].patient.patient_name,
      age: profile.current[index].patient.age,
      image: profile.current[index].process_image,
      graph: profile.current[index].d_plot,
      processDate: profile.current[index].updated_date,
      uploadDate: date
    })
  };

  const getData = async (page) => {
    try {
      setIsLoading(true);
      var anti_aging =  false;
      res = await diagnosisImageId(userToken, page, anti_aging, id);
      profile.current = res.results;
      setCurrentIndex(0);
      setTotalPage(Math.ceil(res.count / 9));

      var date = new moment(res.results[currentIndex].updated_date);
      date = date.subtract(10, 'seconds');
     date = moment(date).format('DD MMM YYYY HH:mm:ss');
      setProfileDetail({
        ...profileDetail,
        name: res.results[currentIndex].patient.patient_name,
        age: res.results[currentIndex].patient.age,
        image: res.results[currentIndex].process_image,
        graph: res.results[currentIndex].d_plot,
        processDate: res.results[currentIndex].updated_date,
        uploadDate: date,
      })

      setCurrentPage(page);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    //console.log("close Clicked")
    setImageDisplay(false)
  }

  useEffect(() => {
    getData(1);
  }, []);

  return (
    <>
      <div className="main-container ">
      {isLoading === true? <Loader/> :
              <div className="profile-box">
                    {displayImage &&
        <SwiperImage images={[largeImage]} handleClose={handleClose}/>}
          <div id="pageHead">
            <div id="pageTitle">
              <Link style={{ textDecoration: "none" }} to="/diagnosis">
                <button className="back-btn">
                  <i className="fa-solid fa-chevron-left"></i>
                </button>
              </Link>
              <h4> Diagnosis Profile</h4>
            </div>
            {/* <div className="filter">
              <div className="menu p-3">
                <p>Filter by {filter}</p>
                <i className="fa-solid fa-chevron-down"></i>
              </div>

              <div className="filter-content col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="filterForm">
                  <button
                    placeholder="Diagnosis"
                    name="diagnosis"
                    id="diagnosis"
                    className={
                      filter === "Diagnosis" ? "btn-active" : "btn-unactive"
                    }
                    onClick={() => {
                      setFilter("Diagnosis");
                      filterKeyRef.current = "diagnosis";
                      filterValueRef.current = "";
                    }}
                  >
                    Diagnosis
                  </button>
                  <button
                    placeholder="anti_aging"
                    name="anti_aging"
                    id="anti_aging"
                    className={
                      filter === "Anti-Aging"
                        ? "btn-active"
                        : "btn-unactive"
                    }
                    onClick={() => {
                      setFilter("Anti-aging");
                      filterKeyRef.current = "anti_aging";
                      filterValueRef.current = "";
                    }}
                  >
                    Anti-Aging
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          <div className="page-content">
          <div className="profileDetail">
<div>

                <div className="profile">
                  <img
                    src={profileDetail.image === " " ? maskphoto : profileDetail.image}
                    alt="Side Photo"
                    className="user-photo"
                    onClick={() => {setImageDisplay(true); setLargeImage(profileDetail.image)}}   
                  />
                              <div className="toggle-div">
              <button className="toggle-btn">
              <input
                type="checkbox"
                id="toggle"
                className="toggleCheckbox"
                onChange={() => {setShowGraph(!showGraph);}}
              />
              <label for="toggle" className="toggleContainer">
                {showGraph===true?(
<div><svg width="24" height="24" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="30.1858" height="30.1858" rx="15.0929" fill="white"/>
<g clip-path="url(#clip0_12_53)">
<path d="M15.4999 25C20.8536 25 25.2623 20.9903 25.9093 15.8125H15.2085L8.6106 22.4104C10.4547 24.0196 12.8605 25 15.4999 25Z" fill="#B07357"/>
<path d="M15.5 4V14.5H26C26 8.70137 21.2986 4 15.5 4Z" fill="#B07357"/>
<path d="M5 14.4999C5 17.1866 6.01719 19.6305 7.6775 21.4876L14.1875 14.9776V14.4999V4.09045C9.00969 4.73752 5 9.1462 5 14.4999Z" fill="#B07357"/>
</g>
<defs>
<clipPath id="clip0_12_53">
<rect width="21" height="21" fill="white" transform="translate(5 4)"/>
</clipPath>
</defs>
</svg>
</div>):(                 <div><svg width="24" height="24" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_12_20)">
<path d="M16.023 30.952C23.7185 30.952 30.0556 25.1884 30.9857 17.7457H15.6041L6.12012 27.2297C8.77081 29.5427 12.229 30.952 16.023 30.952Z" fill="white"/>
<path d="M16.0227 0.766235V15.8592H31.1156C31.1156 7.52409 24.3578 0.766235 16.0227 0.766235Z" fill="white"/>
<path d="M0.929932 15.8592C0.929932 19.7211 2.39206 23.234 4.77863 25.9036L14.1362 16.546V15.8592V0.896484C6.69354 1.82659 0.929932 8.16372 0.929932 15.8592Z" fill="white"/>
</g> 
<defs>
<clipPath id="clip0_12_20">
<rect width="30.1858" height="30.1858" fill="white" transform="translate(0.929932 0.766235)"/>
</clipPath>
</defs>
</svg>
</div>)  }
{showGraph===true?
                (<div><svg width="24" height="24" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.766235" width="30.1858" height="30.1858" rx="15.0929" fill="#C4C4C4"/>
<path d="M15.093 15.8592C17.8715 15.8592 20.124 13.6067 20.124 10.8282C20.124 8.04968 17.8715 5.79724 15.093 5.79724C12.3145 5.79724 10.062 8.04968 10.062 10.8282C10.062 13.6067 12.3145 15.8592 15.093 15.8592Z" fill="white"/>
<path d="M25.1549 24.6634V25.9211C25.1549 26.2547 25.0224 26.5746 24.7865 26.8105C24.5506 27.0464 24.2307 27.1789 23.8972 27.1789H6.28875C5.95517 27.1789 5.63526 27.0464 5.39939 26.8105C5.16352 26.5746 5.03101 26.2547 5.03101 25.9211V24.6634C5.03101 22.662 5.82608 20.7425 7.24131 19.3273C8.65655 17.912 10.576 17.1169 12.5775 17.1169H17.6084C19.6099 17.1169 21.5294 17.912 22.9446 19.3273C24.3598 20.7425 25.1549 22.662 25.1549 24.6634Z" fill="white"/>
</svg>
</div>):(<div><svg width="24" height="24" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="30.1858" height="30.1858" rx="15.0929" fill="white"/>
<path d="M15.093 15.093C17.8715 15.093 20.124 12.8405 20.124 10.062C20.124 7.28345 17.8715 5.03101 15.093 5.03101C12.3145 5.03101 10.062 7.28345 10.062 10.062C10.062 12.8405 12.3145 15.093 15.093 15.093Z" fill="#B07357"/>
<path d="M25.1549 23.8972V25.1549C25.1549 25.4885 25.0224 25.8084 24.7865 26.0443C24.5506 26.2801 24.2307 26.4127 23.8972 26.4127H6.28875C5.95517 26.4127 5.63526 26.2801 5.39939 26.0443C5.16352 25.8084 5.03101 25.4885 5.03101 25.1549V23.8972C5.03101 21.8957 5.82608 19.9763 7.24131 18.561C8.65655 17.1458 10.576 16.3507 12.5775 16.3507H17.6084C19.6099 16.3507 21.5294 17.1458 22.9446 18.561C24.3598 19.9763 25.1549 21.8957 25.1549 23.8972Z" fill="#B07357"/>
</svg></div>)
 }
              </label>
            </button>
            </div>
                  {  showGraph===true? 
                  <img src={profileDetail.graph} className="graph" onClick={() => {setImageDisplay(true);  setLargeImage(profileDetail.graph)}}   />
                  :
                  <div className="detail">
                    <h3>Patient Detail</h3>
                    <div className="photo-description">
                      <div className="description">
                        <p>Patient Name</p>
                        <p>Age</p>
                        <p>Image Upload on</p>
                        <p>Image Processed on</p>
                        <p>Image Purpose </p>
                      </div>

                      <div className="description description-data">
                        <p>{profileDetail.name}</p>
                        <p>{profileDetail.age}</p>
                        <p>{profileDetail.uploadDate}</p>
                        <p>{moment(profileDetail.processDate).format('DD MMM YYYY HH:mm:ss')}</p>
                        <p>Diagnosis</p>
                      </div>
                    </div>
                  </div>}
                </div>
    
            </div>
          </div>
          <div id="paginationContainer">
            <div className="pagination-scroll">
              {profile.current.map((item, i) => (
                <a onClick={() => updateDetails(i)}>
                  <img className={i===currentIndex?"paginationImage active-img" : "paginationImage"} src={item.d_image} />
                </a>
              ))}
            </div>
            {/* <div className="row"> */}
            <PageNav
              index={1}
              currentPage={currentPage}
              TotalPage={totalPage}
              getData={getData}
            />
            {/* </div> */}
          </div>
          </div>
        </div>}
      </div>
    </>
  );
};

export default DiagnosisProfile;
